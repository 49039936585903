"use strict";

export function initInScope($scope) {
    let $btn = $scope.find('.js-copy-to-clipboard__btn');

    $btn.on('click', function () {
        let $wrapper = $(this).closest('.js-copy-to-clipboard');
        let $input = $wrapper.find('.js-copy-to-clipboard__input');
        let $msg = $wrapper.find('.js-copy-to-clipboard__msg');
        let successMsg = $input.attr('data-copy-success') || 'success';
        let errorMsg = $input.attr('data-copy-error') || 'error';
        let $successBtn = $wrapper.find('.js-copy-to-clipboard__success-btn');

        copyToClipboard($input, $msg, successMsg, $successBtn, errorMsg);
    });
}

function copyToClipboard($input, $msg, successMsg, $successBtn, errorMsg) {
    let input = $input[0];
    $input.focus();
    $input.select();
    $msg.attr('hidden', false);

    try {
        //to work in ios
        let oldContentEditable = input.contentEditable,
            oldReadOnly = input.readOnly,
            range = document.createRange();

        input.contentEditable = true;
        input.readOnly = false;
        range.selectNodeContents(input);

        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        input.setSelectionRange(0, 999999);

        input.contentEditable = oldContentEditable;
        input.readOnly = oldReadOnly;

        let copiedText = document.execCommand('copy');
        $msg.text(successMsg);
        $successBtn.attr('disabled', null).addClass('btn-success');
    } catch (err) {
        $msg.text(errorMsg);
        console.log(err);
    }
}