"use strict";

let isRunning = false;
let elements = [];

export function initInScope($scope) {
    let $newElements = $scope.find('.js-affix-bottom');
    if (!$newElements.length) {
        return;
    }


    elements = elements.concat($newElements.toArray().map(function (element) {
        let $element = $(element);
        let $placeholder = $element.closest('.js-affix-bottom__placeholder');
        let $container = $element.closest('.js-affix-bottom__container');

        $element.css('transform', 'translateZ(0)');

        if (!$container || !$container.length) {
            console.warn('Could not find parent ".js-affix-bottom__container" for element ', element);
            return null;
        }

        if (!$placeholder || !$placeholder.length) {
            console.warn('Could not find parent ".js-affix-bottom__placeholder" for element ', element);
            return null;
        }

        if ($placeholder && $placeholder.length) {
            $placeholder.css('min-height', element.getBoundingClientRect().height);
        }


        return {
            element: element,
            placeholder: $placeholder[0],
            container: $container[0],
            isAffix: false,
            offset: +$element.data('affix-offset') || 40,
            affixBottom: +$element.data('affix-bottom') || false
        }
    }).filter(x => !!x));

    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

let lastScrollPosition = 0;
function interval() {
    if (lastScrollPosition === window.scrollY) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = window.scrollY;

    elements.forEach(function (obj) {
        let elementRect =  obj.placeholder.getBoundingClientRect();
        let containerRect = obj.container.getBoundingClientRect();

        if (obj.isAffix) {

            if (isContainerBottomReached(containerRect, elementRect, obj.offset)) {

                $(obj.element).removeClass('is-affix bottom').css({
                    position: 'relative',
                    top:'containerRect.height - elementRect.top',
                    left: '',
                });

                obj.isAffix = false;

            } if (!isAffixTopReached(containerRect, obj.offset)){
                $(obj.element).removeClass('is-affix bottom').css({
                    position: '',
                    top:'',
                    left: '',
                    width: '',
                });

                obj.isAffix = false;
            }
        } else {
            if (isAffixTopReached(containerRect, obj.offset) && !isContainerBottomReached(containerRect, elementRect, obj.offset)) {
                $(obj.element).addClass('is-affix bottom').css({
                    position: 'fixed',
                    bottom: 'obj.offset',
                    left: matchMedia('(max-width: 767px)').matches ? '0' : elementRect.left,
                    width: matchMedia('(max-width: 767px)').matches ? '100%' : elementRect.width,
                });

                obj.isAffix = true;
            }
        }
    });

    requestAnimationFrame(interval);
}

function isContainerBottomReached(containerRect, elementRect, offset) {
    return containerRect.top + containerRect.height  <= $(window).height() + elementRect.height - offset;
}

function isAffixTopReached(elementRect, offset) {
    let offsetWindow =  400;
    return elementRect.top - 40 <= $(window).height() - offsetWindow
}
