"use strict";


export function initInScope ($scope) {
    let $toggleWrapper = $scope.find('.js-toggle-disabled');

    $toggleWrapper.each(function () {
        let $this = $(this);
        $this.addClass('is-disabled');

        let $inputs = $this.find('.js-toggle-disabled__input');
        let $trigger = $this.find('.js-toggle-disabled__trigger');

        $trigger.on('click', function () {
            if($this.hasClass('is-disabled')) {
                $this.removeClass('is-disabled');
            } else {
                $this.addClass('is-disabled');
            }

            $inputs.each(function () {
                if($(this).prop("disabled")) {
                    $(this).attr("disabled", false);
                } else {
                    $(this).attr("disabled", true);
                }
            });
        });
    });

}