"use strict";

import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import initModulesInScope from '@elements/init-modules-in-scope';
import {showNotification, clearAll} from '@elements/alert-notification';
import 'url-search-params-polyfill'; // Edge Polyfill
import 'whatwg-fetch'; // IE10 Polyfill

const configName = '_cartConfig';

let pendingRequest,
    $listLoading,
    $listResult,
    $count;


export function initInScope($scope, {onAdd, onAddSucceed, onAddFailed} = {}) {
    let $cartForm = $scope.find('.js-cart__form');
    let $cartRemove = $scope.find('.js-cart__remove');
    let $cartLoading = $('.js-cart__loading');
    let $cartResult = $('.js-cart__result');
    let $cartTotal = $('.js-cart__total');
    let $count = $('.js-cart__count');


    if ($cartForm && $cartForm.length) {

        let formTrigger = $cartForm.data('trigger') || 'submit';
        let emptyCart = $cartForm.data('empty-cart');
        let cartUpdate = $cartForm.data('cart-update') || false;

        let $cartUpdatePrice = $cartForm.find('.js-cart__update-price');

        $cartUpdatePrice.on('change', function(evt) {
            evt.preventDefault();
            let $form = $(this).closest('.js-cart__form');
            let $result = $form.find('.js-cart__update-price__result');
            let $btn = $form.find('.js-cart__cart-btn');

            $form.addClass('is-loading');
            requireConfigValues(['cartUpdatePriceUrl'], configName);
            const data = new URLSearchParams(new FormData($form[0]));

            data.append("status","update price");

            let request = fetch(getConfigValue('cartUpdatePriceUrl', configName), {
                method: 'post',
                body: data,
            });

            request.then(response => response.json())
                .then(result => {

                    if (result.status === 'success') {
                        let content = result.content || result.html;
                        if (content) {
                            $result.empty().append(content);
                            initModulesInScope($result);
                        }
                        $form.removeClass('is-loading');

                        if(result.showCartBtn) {
                            $btn.attr('disabled', false);
                        } else {
                            $btn.attr('disabled', true);
                        }
                        
                        call(onAddSucceed);
                    } else {
                        call(onAddFailed);
                    }
                }).catch(e => {
                    $form.removeClass('is-loading');
                    call(onAddFailed);
                }
            );
        });


        requireConfigValues(['cartUrl'], configName);

        $cartForm.each(function () {
            $(this).on(formTrigger, function (evt) {
                let $cartNotifications =  $(this).find('.js-cart__notifications');

                evt.preventDefault();
                let $thisForm = $(this);

                const data = new URLSearchParams(new FormData($thisForm[0]));

                if(cartUpdate) {
                    data.append('status', 'update');
                }

                $cartLoading.attr('hidden', null);

                if(emptyCart){
                    $cartResult.attr('hidden', 'hidden');
                }

                clearAll({
                    $container: $('.js-cart__notifications')
                });

                call(onAdd);

                let request = fetch(getConfigValue('cartUrl', configName), {
                    method: 'post',
                    body: data,
                }).then(response => response.json());

                showNotification(request, {
                    $container: $cartNotifications
                });


                request
                    .then(result => {
                        if (result.status === 'success') {
                            let content = result.content || result.html;
                            if (content) {
                                $cartResult.empty().append(result.content);
                                $cartLoading.attr('hidden', 'hidden');
                                $cartResult.attr('hidden', null);
                                initModulesInScope($cartResult);
                            }

                            $cartLoading.attr('hidden', 'hidden');

                            if (typeof result.count !== "undefined") {
                                setCount($count, result.count);
                            }

                            if (typeof result.total !== "undefined") {
                                setTotal($cartTotal, result.total);
                            }

                            if(!cartUpdate) {
                                resetCartAmount($cartForm);
                            }


                            call(onAddSucceed);
                        } else {
                            call(onAddFailed);
                        }
                    }).catch(e => {
                        console.warn(e);
                        $cartLoading.attr('hidden', 'hidden');
                        $cartResult.attr('hidden', 'hidden');
                        call(onAddFailed);
                    }


                );
            });

        });


        // remove product from cart
        $cartRemove.on('click', function (evt) {
            evt.preventDefault();
            $(this).closest('form').find('.js-cart__amount').val('0').trigger('change');
        });
    }
}

function setCount($element, count) {
    if (count) {
        $element.attr('hidden', null);
        $element.text(count);
    } else {
        $element.attr('hidden', 'hidden');
    }
}


function setTotal($element, total) {
    if (total) {
        $element.fadeOut(500, function() {
            $element.text(total).fadeIn(500);
        });
    } else {
        $element.attr('hidden', 'hidden');
    }
}

function resetCartAmount($form) {
    $form.find('.js-cart__amount').val('1');
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
