"use strict";

import Inputmask from "inputmask";

export function initInScope ($scope) {
    let $keycards = $scope.find('.js-keycard');

    $keycards.each(function() {
        Inputmask.extendDefinitions({
            'A': {
                validator: "[aA]",
                casing: "upper"
            },
            'B': {
                validator: "[bB]",
                casing: "upper"
            },
            'U': {
                validator: "[uU]",
                casing: "upper"
            }
        });

        Inputmask().mask($(this));

    });

}