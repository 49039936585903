"use strict";

let isRunning = false;
let $navbar;
let lastScrollPosition = 0;
let isNavOverlayOpen = false;

export function initInScope($scope) {
    $navbar = $scope.find('.js-navbar__affix');

    if ($navbar) {
        navbarToggleAffix();
    }

    if (matchMedia('(max-width: 767px)').matches) {
        $('.js-navbar__toggler').on('click', function (evt) {
            evt.preventDefault();
            let $navWrapper = $scope.find('.js-navbar');
            let $this = $(this);

            if (!$navWrapper.hasClass('is-open')) {
                $navWrapper.addClass('is-open');
                $this.addClass('is-open');
                $this.removeClass('is-closed');
                stopScrolling(true);
                $('html').addClass('is-scrolling-disabled');
            } else {
                $navWrapper.removeClass('is-open');
                $this.removeClass('is-open');
                $this.addClass('is-closed');
                stopScrolling(false);
                $('html').removeClass('is-scrolling-disabled');
            }
        });
    }
}

function navbarToggleAffix() {
    if (!isRunning) {
        isRunning = true;
        requestAnimationFrame(interval);
    }
}

function interval() {
    let scrollTopPosition = $(window).scrollTop();
    if (lastScrollPosition === scrollTopPosition || isNavOverlayOpen) {
        requestAnimationFrame(interval);
        return;
    }

    lastScrollPosition = scrollTopPosition;
    updateDom();
    requestAnimationFrame(interval);
}

function updateDom() {
    let offset = $navbar.attr('data-affix-offset') || 0;
    let scrollTopPosition = $(window).scrollTop();

    $navbar.isAffix = offset < scrollTopPosition;

    if ($navbar.isAffix) {
        $navbar.addClass('nav-fixed');
    } else {
        $navbar.removeClass('nav-fixed');
    }
}

function stopScrolling (bool) {
    if (bool === true) {
        document.body.addEventListener("touchmove", freezeViewport, {passive: true});
    } else {
        document.body.removeEventListener("touchmove", freezeViewport, false);
    }
}
let freezeViewport = function(e) {
    e.preventDefault();
};