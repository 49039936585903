"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";
import {whenValid} from "@elements/parsley-bootstrap-validation";
import {
    init,
    showNotification,
    showNotificationByPromise,
    clearAll,
    PLACEMENT_TYPES
} from "@elements/alert-notification";

export function initInScope($scope) {
    $scope.find('.js-ajax-modal').each(function () {
        let $modal = $(this);

        initModal($modal);
    });
    

    $scope.find('.js-ajax-modal__open').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);
        let $target = $($this.data('target'));
        let $form = $this.closest('.js-ajax-modal__open-form');
        

        whenValid($form).then((isValid) => {
            if (isValid) {
                // set ajax url from button if defined
                if ($this.data('ajax-modal-url')) {
                    $target.data('ajax-url', $this.data('ajax-modal-url'));
                }
                // set ajax params from button if defined
                if ($this.data('ajax-modal-params')) {
                    $target.data('ajax-params', $this.data('ajax-modal-params'));
                }

                if($this.closest('.js-ajax-modal__open-form').length > 0) {
                    let $form = $this.closest('.js-ajax-modal__open-form');
                    let data = $form.serializeArray();
                    $target.data('ajax-params', data);
                }

                if($this.closest('.js-ajax-modal__add-form-field-params').length > 0) {
                    let $wrapper = $this.closest('.js-ajax-modal__add-form-field-params');
                    let data = [];

                    $wrapper.find('input, select, textarea').each(function() {
                        let itemData = {
                            name: $(this).attr('name'),
                            value: $(this).val()
                        };
                        data.push(itemData);
                    });

                    $target.data('ajax-params', data);
                }
            } else {
              e.preventDefault();
              e.stopImmediatePropagation();
            }
        });

    });
}

function initModal($modal) {
    $modal.find('.js-ajax-modal__loading').attr('hidden', null);
    $modal.find('.js-ajax-modal__content').attr('hidden', 'hidden');

    $modal.on('shown.bs.modal', onShowHandler);
}

function onShowHandler() {
    let $modal = $(this);
    let url = $modal.data('ajax-url');


    if ($modal.data('ajax-config-url')
        && _config[$modal.data('ajax-config-url')] ) {
        url = _config[$modal.data('ajax-config-url')];
    }

    if (!url) {
        console.error('Can\'t load content for js-ajax-modal ', this, '. data-ajax-url was not set.');
        // closeModal(this);
        return;
    } else {
        $modal.find('.js-ajax-modal__loading').attr('hidden', null);
        $modal.find('.js-ajax-modal__content').attr('hidden', 'hidden');

        $.ajax(url, {
            dataType: 'json',
            data: $modal.data('ajax-params'),
            cache: false
        }).done(function (result) {
            if(result.status == 'success') {
                $modal.removeClass('is-loading');
                $modal.find('.js-ajax-modal__loading').attr('hidden', 'hidden');

                let $result = $('<div>').html(result.html);
                let $content = $modal.find('.js-ajax-modal__content');
                $content.empty()
                    .append($result)
                    .attr('hidden', null);

                initModulesInScope($content);
                // $modal.triggerHandler('ajaxModal.loaded');
            } else {
                // $modal.triggerHandler('ajaxModal.failed');
                $modal.removeClass('is-loading');
                $modal.find('.js-ajax-modal__loading').attr('hidden', 'hidden');
                showNotification(pendingRequest);

            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.error(textStatus, errorThrown, jqXHR);
            // $modal.triggerHandler('ajaxModal.failed');
            $modal.removeClass('is-loading');
            $modal.find('.js-ajax-modal__loading').attr('hidden', 'hidden');
        });
    }

}


export function setModalAjaxUrl($modal, url) {
    $modal.data('ajax-url', url);
}
