import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as toc from '@elements/toc';
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as datepicker from './scripts/datepicker';
app.modules.datepicker = datepicker.initInScope;
 
import * as datepickerRange from './scripts/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

console.log("script btob2");

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as floatingLabels from '@elements/floating-labels';
app.modules.floatingLabels = floatingLabels.initInScope;

import * as navbar from './scripts/btob/navbar';
app.modules.navbar = navbar.initInScope;

import * as cart from './scripts/btob/cart';
app.modules.cart = cart.initInScope;

import * as keycard from './scripts/btob/keycard';
app.modules.keycard = keycard.initInScope;

import * as copyToClipboard from './scripts/btob/copyToClipboard';
app.modules.copyToClipboard = copyToClipboard.initInScope;

import * as toggleDisabled from './scripts/btob/toggleDisabled';
app.modules.toggleDisabled = toggleDisabled.initInScope;

import * as ajaxModal from './scripts/btob/ajaxModal';
app.modules.ajaxModal = ajaxModal.initInScope;

import * as isInViewport from './scripts/isInViewport';
app.modules.isInViewport = isInViewport.initInScope;

import * as numberSpinner from './scripts/numberSpinner';
app.modules.numberSpinner = numberSpinner.initInScope;

import * as affixBottom from './scripts/affixBottom';
app.modules.affixBottom = affixBottom.initInScope;

import * as actionChanger from '@elements/action-changer';
app.modules.actionChanger = actionChanger.initInScope;

import {init} from '@elements/alert-notification';
init({
    // default options
});


import { showCookieBar } from "@elements/cookie-permissions";
/*if (!_config.admin && !_config.editmode) {
    showCookieBar();
}*/

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);